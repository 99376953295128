(function() {
    'use strict';

    angular.module('ionicComment', [
        'ionic',
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicComment').service('CommentService', CommentService);

    CommentService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'ListManagementService',
        'LoginService',
        'RequestManagementService'
    ];

    function CommentService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        ListManagementService,
        LoginService,
        RequestManagementService
    ) {
        var CommentService = this;

        CommentService.createComment = createComment;
        function createComment(newComment, parentType, parentObject) {
            return LoginService.getActiveUser().then(
                function(activeUser) {
                    newComment.user_id = activeUser.id;
                    newComment[parentType] = [{
                        id: parentObject.id
                    }];

                    var options = RequestManagementService.getRequest();

                    options = RequestManagementService.setModel(options, 'comments');
                    options = RequestManagementService.setAction(options, 'add');

                    options = RequestManagementService.setData(options, newComment);

                    return DataManagementService.request(options).then(
                        function(data) {
                            if (data) {
                                var comment = DefaultVariableService.get(
                                    data.comments,
                                    false
                                );

                                if (comment) {
                                    if (comment.user_id === activeUser.id) {
                                        comment.user = activeUser;
                                    }

                                    parentObject.comments = DefaultVariableService.getArray(
                                        parentObject.comments
                                    );

                                    parentObject.comments = ListManagementService.replaceInList(
                                        comment,
                                        parentObject.comments
                                    );

                                    return parentObject;
                                }
                            }

                            return false;
                        }
                    );
                }
            );
        }

        CommentService.deleteComment = deleteComment;
        function deleteComment(comment, parentObject) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'comments');
            options = RequestManagementService.setAction(options, 'delete', comment.id);

            options = RequestManagementService.setData(options, comment);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        parentObject.comments = DefaultVariableService.getArray(
                            parentObject.comments
                        );

                        parentObject.comments = ListManagementService.removeFromList(
                            comment,
                            parentObject.comments
                        );

                        return parentObject;
                    }

                    return false;
                }
            );
        }

        CommentService.reportComment = reportComment;
        function reportComment(comment) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'monitor');
            options = RequestManagementService.setAction(options, 'report', 'comments/' + comment.id);

            return DataManagementService.request(options);
        }

        CommentService.reset = reset;
        function reset() {

        }

        CommentService.reset();

        return CommentService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').controller(
        'CommentController',
        CommentController
    );

    CommentController.$inject = [
        'AlertService',
        'CommentService',
        'DefaultImageService',
        'DefaultVariableService',
        'MomentService',
        'RedirectService',
        '$scope'
    ];

    function CommentController(
        AlertService,
        CommentService,
        DefaultImageService,
        DefaultVariableService,
        MomentService,
        RedirectService,
        $scope
    ) {
        var CommentController = this;

        $scope.$watch(
            'activeUser',
            function(activeUser) {
                CommentController.activeUser = DefaultVariableService.getObject(
                    activeUser
                );
            }.bind(CommentController)
        );

        $scope.$watch(
            'comment',
            function(comment) {
                CommentController.comment = DefaultVariableService.getObject(
                    comment
                );
            }.bind(CommentController)
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                CommentController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }.bind(CommentController)
        );

        $scope.$watch(
            'parentObject',
            function(parentObject) {
                CommentController.parentObject = DefaultVariableService.getObject(
                    parentObject
                );
            }.bind(CommentController)
        );

        $scope.$watch(
            'showDelete',
            function(showDelete) {
                CommentController.showDelete = DefaultVariableService.get(
                    showDelete,
                    false
                );
            }.bind(CommentController)
        );

        CommentController.deleteComment = deleteComment;
        function deleteComment(comment) {
            return CommentService.deleteComment(
                comment,
                CommentController.parentObject
            ).then(
                function(response) {
                    CommentController.onDelete(comment);

                    AlertService.addMessage('COMMENT DELETED.');

                    return response;
                }
            );
        }

        CommentController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        CommentController.goToUser = goToUser;
        function goToUser(user) {
            user = DefaultVariableService.getObject(
                user
            );

            var params = {};
            if (user.id !== 0) {
                params.userId = user.id;
            }

            RedirectService.goTo(
                CommentController.userSref,
                params
            );

            return DefaultImageService.getUserImage(user);
        }

        CommentController.reportComment = reportComment;
        function reportComment(comment) {
            return CommentService.reportComment(
                comment,
                CommentController.parentObject
            ).then(
                function() {
                    CommentController.onReport(comment);
                }
            );
        }

        CommentController.reset = reset;
        function reset() {
            CommentController.moment = MomentService;

            CommentController.userSref = DefaultVariableService.get(
                CommentController.userSref,
                'app.profile'
            );
        }

        CommentController.init = init;
        function init() {
            CommentController.reset();
        }

        CommentController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').directive(
        'ionicComment',
        ionicComment
    );

    function ionicComment() {
        return {
            bindToController: {
                userSref: '@'
            },
            controller:   'CommentController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                activeUser:   '=',
                comment:      '=',
                onDelete:     '=',
                parentObject: '=',
                showDelete:   '='
            },
            template:'<a class="item item-avatar item-button-right"><img data-ng-src="{{ ctrl.getUserImage(comment.user) }}"><div class="buttons"><button data-ng-if="ctrl.showDelete" class="button button-clear decline-color" data-ng-click="ctrl.deleteComment(comment)"><i class="icon ion-trash-b"></i></button> <button data-ng-if="!ctrl.showDelete && ctrl.onReport" class="button button-clear" data-ng-click="ctrl.reportComment(comment)"><i class="icon ion-flag decline-color"></i></button></div><h2 data-ng-click="ctrl.goToUser(comment.user)">{{ comment.user.full_name }}</h2><p>{{ ctrl.moment(comment.created).fromNow() }}</p></a><div class="item item-body">{{ comment.comment }}</div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').controller(
        'CommentCreateController',
        CommentCreateController
    );

    CommentCreateController.$inject = [
        'AlertService',
        'CommentService',
        'DefaultImageService',
        'DefaultVariableService',
        '$scope'
    ];

    function CommentCreateController(
        AlertService,
        CommentService,
        DefaultImageService,
        DefaultVariableService,
        $scope
    ) {
        var CommentCreateController = this;

        $scope.$watch(
            'activeUser',
            function(activeUser) {
                CommentCreateController.activeUser = DefaultVariableService.getObject(
                    activeUser
                );
            }.bind(CommentCreateController)
        );

        $scope.$watch(
            'onAdd',
            function(onAdd) {
                CommentCreateController.onAdd = DefaultVariableService.getPromise(
                    onAdd
                );
            }.bind(CommentCreateController)
        );

        $scope.$watch(
            'parentObject',
            function(parentObject) {
                CommentCreateController.parentObject = DefaultVariableService.getObject(
                    parentObject
                );
            }.bind(CommentCreateController)
        );

        $scope.$watch(
            'placeholder',
            function(placeholder) {
                CommentCreateController.placeholder = DefaultVariableService.get(
                    placeholder,
                    'COMMENT'
                );
            }.bind(CommentCreateController)
        );

        CommentCreateController.addComment = addComment;
        function addComment(newComment) {
            newComment = DefaultVariableService.get(
                newComment,
                CommentCreateController.newComment
            );

            CommentCreateController.isCreatingComment = true;

            return CommentService.createComment(
                newComment,
                CommentCreateController.parentType,
                CommentCreateController.parentObject
           ).then(
               function(response) {
                   if (response) {
                       CommentCreateController.onAdd(newComment);

                       AlertService.addMessage('COMMENT CREATED.');

                       CommentCreateController.resetNewComment();
                   }

                   return response;
               }
           ).finally(
               function() {
                   CommentCreateController.isCreatingComment = false;
               }
            );
        }

        CommentCreateController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        CommentCreateController.reset = reset;
        function reset() {
            CommentCreateController.activeUser = DefaultVariableService.getObject();

            CommentCreateController.expand = false;

            CommentCreateController.parentType = DefaultVariableService.getString(
                CommentCreateController.parentType,
                'posts'
            );

            CommentCreateController.resetNewComment();
        }

        CommentCreateController.resetNewComment = resetNewComment;
        function resetNewComment() {
            CommentCreateController.newComment = {};
            CommentCreateController.newComment.comment = '';
        }

        CommentCreateController.toggleExpand = toggleExpand;
        function toggleExpand() {
            CommentCreateController.expand = !CommentCreateController.expand;
        }

        CommentCreateController.init = init;
        function init() {
            CommentCreateController.reset();
        }

        CommentCreateController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').directive(
        'ionicCommentCreate',
        ionicCommentCreate
    );

    function ionicCommentCreate() {
        return {
            bindToController: {
                parentType:   '@'
            },
            controller:   'CommentCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                activeUser:   '=',
                onAdd:        '=',
                parentObject: '=',
                placeholder:  '='
            },
            template:'<ion-item class="list card" data-ng-hide="ctrl.isCreatingComment"><a class="item item-avatar"><img data-ng-src="{{ ctrl.getUserImage(ctrl.activeUser) }}"><h2>{{ ctrl.activeUser.full_name }}</h2></a><form data-ng-submit="ctrl.addComment()"><div class="item item-input"><textarea data-ng-model="ctrl.newComment.comment" data-ng-class="{ expandedNewComment: ctrl.expand }" data-ng-click="ctrl.toggleExpand()" placeholder="{{ ctrl.placeholder }}">\n                {{ ctrl.newComment.comment }}\n            </textarea></div><button type="submit" class="button button-full button-positive accept-button" data-ng-show="ctrl.newComment.comment && ctrl.newComment.comment.length !== 0" data-ng-click="ctrl.addComment()" data-ng-disabled="ctrl.isCreatingComment">{{ ctrl.isCreatingComment ? \'COMMENTING\' : \'COMMENT\' }}</button></form></ion-item><span data-ng-show="ctrl.isCreatingComment"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').controller(
        'CommentListController',
        CommentListController
    );

    CommentListController.$inject = [
        'AlertService',
        'DefaultImageService',
        'DefaultVariableService',
        'LoginService',
        '$scope'
    ];

    function CommentListController(
        AlertService,
        DefaultImageService,
        DefaultVariableService,
        LoginService,
        $scope
    ) {
        var CommentListController = this;

        CommentListController.activeUser = DefaultVariableService.getObject();

        $scope.$watch(
            'activeUser',
            function(activeUser) {
                if (DefaultVariableService.isObject(activeUser)) {
                    CommentListController.activeUser = DefaultVariableService.getObject(
                        activeUser
                    );
                } else {
                    CommentListController.loadActiveUser();
                }
            }.bind(CommentListController)
        );

        $scope.$watch(
            'comments',
            function(comments) {
                if (typeof comments !== 'undefined') {
                    CommentListController.comments = DefaultVariableService.getArray(
                        comments
                    );

                    CommentListController.isLoadingComments = false;
                }
            }.bind(CommentListController)
        );

        $scope.$watch(
            'onAdd',
            function(onAdd) {
                CommentListController.onAdd = DefaultVariableService.getPromise(
                    onAdd
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                CommentListController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'onReport',
            function(onReport) {
                CommentListController.onReport = DefaultVariableService.get(
                    onReport,
                    false
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'parentObject',
            function(parentObject) {
                CommentListController.parentObject = DefaultVariableService.getObject(
                    parentObject
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'showCommentList',
            function(showCommentList) {
                CommentListController.showCommentList = DefaultVariableService.get(
                    showCommentList,
                    true
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'showDelete',
            function(showDelete) {
                CommentListController.showDelete = DefaultVariableService.get(
                    showDelete,
                    true
                );
            }.bind(CommentListController)
        );

        $scope.$watch(
            'showNewComment',
            function(showNewComment) {
                CommentListController.showNewComment = DefaultVariableService.get(
                    showNewComment,
                    false
                );
            }.bind(CommentListController)
        );

        CommentListController.addComment = addComment;
        function addComment(comment) {
            return CommentListController.onAdd(comment);
        }

        CommentListController.canDeleteComment = canDeleteComment;
        function canDeleteComment(comment) {
            if (CommentListController.showDelete === false) {
                return false;
            }

            if (CommentListController.activeUser.id === 0) {
                return false;
            }

            var isSameUser = CommentListController.activeUser.id === comment.user_id;
            var isAdmin = CommentListController.activeUser.user_type.name === 'admin';

            return (isSameUser || isAdmin);
        }

        CommentListController.loadActiveUser = loadActiveUser;
        function loadActiveUser() {
            return LoginService.getActiveUser().then(
                function(activeUser) {
                    if (activeUser) {
                        CommentListController.activeUser = activeUser;
                    }

                    return activeUser;
                }
            );
        }

        CommentListController.reset = reset;
        function reset() {
            CommentListController.isLoadingComments = true;
        }

        CommentListController.init = init;
        function init() {
            CommentListController.reset();
        }

        LoginService.register(CommentListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicComment').directive(
        'ionicCommentList',
        ionicCommentList
    );

    function ionicCommentList() {
        return {
            bindToController: {
                userSref:   '@',
                parentType: '@'
            },
            controller:   'CommentListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                activeUser:      '=',
                comments:        '=',
                onAdd:           '=',
                onDelete:        '=',
                onReport:        '=',
                parentObject:    '=',
                showDelete:      '=',
                showCommentList: '=',
                showNewComment:  '='
            },
            template:'<div data-ng-if="!ctrl.isLoadingComments" class="animated fadeInDown"><div data-ng-if="ctrl.showCommentList"><ion-item class="list card fadeInDown" data-ng-repeat="comment in ctrl.comments"><ionic-comment active-user="ctrl.activeUser" comment="comment" on-delete="ctrl.onDelete" parent-object="ctrl.parentObject" show-delete="ctrl.canDeleteComment(comment)"></ionic-comment></ion-item></div><div data-ng-if="ctrl.showCommentList || ctrl.showNewComment"><ionic-comment-create active-user="ctrl.activeUser" on-add="ctrl.addComment" parent-object="ctrl.parentObject" parent-type="{{ ctrl.parentType }}"></ionic-comment-create></div></div><span data-ng-if="ctrl.isLoadingComments"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();